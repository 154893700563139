class FormattingService {
    /**
     * Formats a string or number with the selected mask.
     * @param mask The mask to format the string / number with
     * @param value The value to format
     */
    public maskString(mask: string, value?: string | number) {
        const valueString = value?.toString();
        if (valueString == null) { return ''; }
        let result = '';

        // Iterate over the mask
        let maskIndex = mask.length - 1;
        for (let i = valueString.length - 1; i >= 0; i--) {
            const char = valueString[i];

            if (maskIndex < 0) {
                // If the string exceeds the mask, just return the rest
                result = valueString.substring(i, 0) + result;
                break;
            }

            // If the currente mask index is not a number, add a separator
            while (mask[maskIndex] !== '#') {
                result = '-' + result;
                maskIndex--;
            }

            // Add the current character
            result = char + result;

            // Move to the next mask index
            maskIndex--;
        }

        return result;
    }

    /**
     * Formats an ISBN number.
     * @param value The ISBN to format
     */
    public formatIsbn(value?: string | number) {
        // ISBN mask
        return this.maskString('###-##-####-###-#', value);
    }

    /**
     * Formats an ISSN number.
     * @param value The ISSN to format
     */
    public formatIssn(value?: string | number) {
        // ISSN mask
        return this.maskString('####-####', value);
        
    }
}

const formattingService = new FormattingService();
export default formattingService;