import stateStore from "@/store/state-store";
import { Pocket, PocketSummary } from "./models";
import stateCache from "@/store/state-cache";

export type method = 'get' | 'post' | 'put' | 'delete' | 'patch' | 'head' | 'options';

export type imageSize = 'small' | 'large' | 'original';

class Api {
    private url = `${process.env.VUE_APP_BACKEND_URL}/api`;
    private apiKey = 'bWoCMZ9fr/dKQWWcA3OURsSM3q3TVJbkSPXF4BRUtQ2cZ9CR9uzhVg==';

    public getAllPockets(): Promise<PocketSummary[]> {
        return this.request<PocketSummary[]>('get', 'pocket');
    }

    public async getPocketDetails(number: number): Promise<Pocket> {
        // Try retrieve the details from the cache
        const cachedDetails = stateStore.state.allPocketDetails[number];

        // If the details were cached, return them
        if (cachedDetails != null) {
            return cachedDetails;
        }

        // Retrieve the cover from the server if not found in the cache
        const response = await this.request<Pocket>('get', `pocket/${number}`);

        // Cache the response and return
        stateStore.addPocketDetails(response);
        return response;
    }
    
    public async getPocketCoverImages(fromNumber: number, toNumber: number, size: imageSize) {
        const cachedImages: any = {};

        // Try retrieve the cover from the cache
        let firstNotCached: number | undefined = undefined;
        for (let i = fromNumber; i <= toNumber; i++) {
            const cover = size === 'large' ? stateCache.state.allCoversLarge[i] : stateCache.state.allCovers[i];
            if (cover == null) {
                firstNotCached = i;
                break;
            }

            cachedImages[i] = cover;
        }

        // If all images were cached, return them
        if (firstNotCached == null) {
            return cachedImages;
        }

        // Retrieve the cover from the server if not found in the cache
        const url = `image/${firstNotCached}/${toNumber}/${size}${this.parameters}`;
        const response = await this.request<any>('get', url);

        // Cache the response
        for (const number in response) {
            if (size === 'large') {
                stateCache.addCoversLarge({[number]: response[number]});
            } else if (size === 'small') {
                stateCache.addCovers({ [number]: response[number] });
            }
        }

        return Object.assign(cachedImages, response);
    }
    
    public async getPocketSpineImages(fromNumber: number, toNumber: number, size: imageSize) {
        const cachedImages: any = {};

        // Try retrieve the cover from the cache
        let firstNotCached: number | undefined = undefined;
        for (let i = fromNumber; i <= toNumber; i++) {
            const spine = size === 'large' ? stateCache.state.allSpinesLarge[i] : stateCache.state.allSpines[i];
            if (spine == null) {
                firstNotCached = i;
                break;
            }

            cachedImages[i] = spine;
        }

        // If all images were cached, return them
        if (firstNotCached == null) {
            return cachedImages;
        }

        // Retrieve the cover from the server if not found in the cache
        const url = `spine/${fromNumber}/${toNumber}/${size}${this.parameters}`;
        const response = await this.request<any>('get', url);

        // Cache the response
        for (const number in response) {
            if (size === 'large') {
                stateCache.addSpinesLarge({[number]: response[number]});
            } else if (size === 'small') {
                stateCache.addSpines({ [number]: response[number] });
            }
        }

        return Object.assign(cachedImages, response);
    }
    
    public setPocket(pocket: Pocket): Promise<void> {
        return this.request<void>('post', `pocket`, pocket);
    }

    public setPocketImage(number: number, fileData: File | Blob): Promise<void> {
        return this.request<void>('post', `image/${number}`, fileData);
    }

    public setPocketSpine(number: number, fileData: File | Blob): Promise<void> {
        return this.request<void>('post', `spine/${number}`, fileData);
    }

    getUniqueIdeasByNames() {
        return this.request<string[]>('get', 'unique-ideas-by');
    }

    getUniqueScriptsByNames() {
        return this.request<string[]>('get', 'unique-scripts-by');
    }

    getUniqueDrawingsByNames() {
        return this.request<string[]>('get', 'unique-drawings-by');
    }

    private get parameters() {
        return `?code=${this.apiKey}&clientId=web`;
    }

    /**
     * Sends a request to the backend and returns the response.
     * @param method The type of request to make.
     * @param action The action to perform on the backend.
     * @param data Any data to send to the backend.
     */
    private async request<T>(method: method, action: string, data?: any): Promise<T> {
        const url = `${this.url}/${action}${this.parameters}`;

        const isBlob = data instanceof Blob;
        const isFile = data instanceof File;

        const body = data == null ? undefined
            : isBlob ? data
            : isFile ? data
            : JSON.stringify(data);

        const contentType = data == null ? 'application/json'
            : isBlob ? data.type
            : isFile ? data.type
            : 'application/json'

        // Fetch data from the API
        const request = new Request(url, {
            method: method,
            body,
            headers: {
                'Content-Type': contentType
            }
        });

        const response = await fetch(request);
        
        if (response.status === 204) { return undefined as any; } // No content
        
        // Parse the response body as an object
        try {
            const text = await response.text();
            if (text === null || text === '') { return undefined as any; } // No proper content

            const body = JSON.parse(text);
            return body;
        } catch (err) {
            // Do nothing, just return nothing
            return undefined as any;
        }
    }
}

const api = new Api();
export default api;