export interface PocketSummary {
    /**
     * A unique identifier of this pocket. Not associated with the physical pocket in any way.
     */
    id?: string;

    /**
     * The number of the pocket.
     */
    number: number;

    /**
     * The title of the pocket. Not all pockets have titles, but most of them.
     */
    title: string;

    /**
     * Determines if this pocket is very worn. If a new unreissued version is found in better condition it may be replaced.
     */
    worn: boolean;

    /**
     * Determines if the pages in this pocket is fully colored.
     */
    fullColor: boolean;

    /**
     * Determines if this pocket has a small image or not.
     */
    hasSmallImage?: boolean;

    /**
     * The total number of pages in this pocket.
     */
     noPages: number;
}

/**
 * A pocket in this database.
 */
export interface Pocket {
    /**
     * A unique identifier of this pocket. Not associated with the physical pocket in any way.
     */
    id?: string;

    /**
     * The number of the pocket.
     */
    number: number;

    /**
     * The title of the pocket. Not all pockets have titles, but most of them.
     */
    title?: string;

    /**
     * The year this pocket was published.
     */
    year: number;

    /**
     * The total number of pages in this pocket.
     */
    noPages: number;

    /**
     * The ISBN number for this pocket.
     */
    isbn?: string;

    /**
     * The ISSN number for this pocket. Not all pockets have an ISSN number, but the later ones do.
     */
    issn?: string;

    /**
     * The name of the publisher of this pocket when it was published.
     */
    publisher?: string;

    /**
     * The name of the publisher of this pocket when it was published.
     */
    reissue: boolean;

    /**
     * Determines if this pocket is very worn. If a new unreissued version is found in better condition it may be replaced.
     */
    worn: boolean;

    /**
     * Determines if the pages in this pocket is fully colored.
     */
    fullColor: boolean;

    /**
     * The stories in this pocket.
     */
    stories: Story[];
}

/**
 * A story contained in a pocket.
 */
export interface Story {
    /**
     * All modern Disney stories has a unique identifier.
     */
    id?: string;

    /**
     * The page this story starts at.
     */
    page: number;

    /**
     * The main character(s) of this story.
     */
    mainCharacter: number[];

    /**
     * The title of this story.
     */
    title: string;

    /**
     * The person(s) with the idea for the story.
     */
     ideaBy: string[];

    /**
     * The author(s) of this story.
     */
    scriptBy: string[];

    /**
     * Who made the drawings of this story.
     */
    drawingsBy: string[];
}

export interface MainCharacter {
    text: string;
    number: number;
}

export const allMainCharacters: Map<number, string> = new Map([
    [1, "Kalle Anka"],
    [2, "Musse Pigg"],
    [3, 'Farbror Joakim'],
    [4, 'Stål-Kalle'],
    [5, 'Kajsa Anka'],
    [6, 'Mårten Gås'],
    [7, 'Långben'],
    [8, 'Ludwig von Anka'],
    [9, 'Mimmi Pigg'],
    [10, 'Kusin Knase'],
    [11, 'Eta Beta'],
    [12, 'Björnfarfar'],
    [13, 'Nemesis'],
    [14, 'Alexander Lukas'],
    [15, 'Oppfinnar-Jocke'],
    [16, 'Newton'],
    [17, 'Magica de Hex'],
    [18, 'Fabian Fånflabb'],
    [19, 'Kommissarie Karlsson'],
    [20, 'Deckare Kalle'],
    [21, 'Farmor Anka'],
    [22, 'Björnligan'],
    [23, 'Svarte-Petter'],
    [24, 'Spökplumpen'],
    [25, 'Knatte, Fnatte och Tjatte'],
    [26, 'Knattarna'],
    [27, 'Teddi och Freddi'],
    [28, 'Gröngölingarna'],
    [29, 'Fantomius'],
    [30, 'Gittan'],
    [31, 'Kickorna'],
    [32, 'Inspektör Klovén'],
    [33, 'Klasse'],
    [34, 'DubbelDeckarn'],
    [35, 'Indiana Jöns'],
    [36, 'Ank-Sofie'],
    [37, 'Kisse'],
    [38, 'Ankeborgspolisen'],
    [39, 'Albert'],
    [40, 'von Pluring'],
    [41, 'Nilsson'],
    [42, 'Klarabella'],
    [43, 'Pluto'],
    [44, 'Adrian'],
    [45, 'Stormogulen'],
    [46, 'Batank'],
    [47, 'OK Kvack']
]);
