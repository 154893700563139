import { reactive, readonly, UnwrapNestedRefs, watch, watchEffect } from 'vue';

export enum StoreType {
    memory,
    localStorage,
    sessionStorage
}

export abstract class StoreBase<T extends Record<string, any>> {
    protected reactiveState: UnwrapNestedRefs<T>;

    constructor(type: StoreType, storageName: string) {
        let data;

        if (type === StoreType.memory) {
            // Memory store, just creates a new state object from data()
            data = this.defaultData();

        } else if (type === StoreType.localStorage) {
            const jsonStore = localStorage.getItem(storageName);
            data = jsonStore == null ? this.defaultData() : JSON.parse(jsonStore);

        } else if(type === StoreType.sessionStorage) {
            const jsonStore = sessionStorage.getItem(storageName);
            data = jsonStore == null ? this.defaultData() : JSON.parse(jsonStore);

        } else {
            throw new Error('Invalid store type');
        }

        this.setup(data);
        this.reactiveState = reactive(data);

        // Automatically save changes to storage (if not memory-only store)
        watchEffect(() => {
            if (type === StoreType.localStorage) {
                localStorage.setItem(storageName, JSON.stringify(this.reactiveState));

            } else if (type === StoreType.sessionStorage) {
                sessionStorage.setItem(storageName, JSON.stringify(this.reactiveState));
            }
        });
    }

    /**
     * Override to provide the initial state of the store.
     */
    protected abstract defaultData(): T;

    /**
     * Override to setup any initial state after it has been loaded.
     */
    protected setup(data: T): void { }

    /**
     * Gets the state as read only, cannot be mutated.
     */
    public get state() {
        return readonly(this.reactiveState) as T;
    }
}
