import { createApp } from 'vue';
import App from './app.vue';
import './registerServiceWorker';
import router from './router';
import Loading from './components/loading.vue';

// Import font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

createApp(App)
    .component('fa', FontAwesomeIcon)
    .component('loading', Loading)
    .use(router)
    .mount('#app');
