import { Pocket, PocketSummary } from '@/api/models';
import { reactive } from 'vue';
import { StoreBase, StoreType } from './store-base';

/**
 * The store interface
 */
export interface StateCache extends Object {
    allSpines: any;
    allCovers: any;
    allCoversLarge: any;
    allSpinesLarge: any;
}

class Store extends StoreBase<StateCache> {
    constructor() {
        super(StoreType.sessionStorage, 'pocket-cache');
    }

    protected defaultData(): StateCache {
        return {
            allSpines: {},
            allCovers: {},
            allCoversLarge: {},
            allSpinesLarge: {}
        };
    }

    public clearCache() {
        this.reactiveState.allSpines = {};
        this.reactiveState.allCovers = {};
        this.reactiveState.allCoversLarge = {};
        this.reactiveState.allSpinesLarge = {};
    }

    public addSpines(newSpines: object) {
        const newObject = reactive(Object.assign(this.reactiveState.allSpines, newSpines));
        this.reactiveState.allSpines = newObject;
    }

    public clearSpines() {
        this.reactiveState.allSpines = {};
    }

    public addCovers(newCovers: object) {
        const newObject = reactive(Object.assign(this.reactiveState.allCovers, newCovers));
        this.reactiveState.allCovers = newObject;
    }

    public clearCovers() {
        this.reactiveState.allCovers = {};
    }

    public addCoversLarge(newCoversLarge: object) {
        const newObject = reactive(Object.assign(this.reactiveState.allCoversLarge, newCoversLarge));
        this.reactiveState.allCoversLarge = newObject;
    }

    public clearCoversLarge() {
        this.reactiveState.allCoversLarge = {};
    }
    
    public addSpinesLarge(newSpinesLarge: object) {
        const newObject = reactive(Object.assign(this.reactiveState.allSpinesLarge, newSpinesLarge));
        this.reactiveState.allSpinesLarge = newObject;
    }

    public clearSpinesLarge() {
        this.reactiveState.allSpinesLarge = {};
    }
    public clearPocketNumberCache(pocketNumber: number) {
        delete this.reactiveState.allSpines[pocketNumber];
        delete this.reactiveState.allCovers[pocketNumber];
        delete this.reactiveState.allCoversLarge[pocketNumber];
        delete this.reactiveState.allSpinesLarge[pocketNumber];
    }
}

const stateStore: Store = new Store();
export default stateStore;