import { Pocket, PocketSummary } from '@/api/models';
import { reactive } from 'vue';
import { StoreBase, StoreType } from './store-base';

export enum DisplayMode {
    List,
    Details,
    Edit
}

/**
 * The store interface
 */
export interface StateStore extends Object {
    selectedPocket?: number;
    highestPocketNumber: number;
    displayMode: DisplayMode;
    allPockets?: PocketSummary[];
    allPocketDetails: any;
    // allSpines: any;
    // allCovers: any;
    // allCoversLarge: any;
    // allSpinesLarge: any;
}

class Store extends StoreBase<StateStore> {
    constructor() {
        super(StoreType.memory, 'store');
    }

    protected defaultData(): StateStore {
        return {
            highestPocketNumber: 1,
            displayMode: DisplayMode.List,
            allPocketDetails: {},
            // allSpines: {},
            // allCovers: {},
            // allCoversLarge: {},
            // allSpinesLarge: {}
        };
    }

    public setSelectedPocket(number?: number) {
        this.reactiveState.selectedPocket = number;
    }

    public clearSelectedPocket() {
        this.reactiveState.selectedPocket = undefined;
    }

    public setNextSelectedPocket() {
        let newNumber = (this.reactiveState.selectedPocket || 0) + 1;

        if (newNumber > this.reactiveState.highestPocketNumber) {
            newNumber = 1;
        }

        this.reactiveState.selectedPocket = newNumber;
    }

    public setPreviousSelectedPocket() {
        let newNumber = (this.reactiveState.selectedPocket || 0) - 1;
        
        if (newNumber <= 0) {
            newNumber = this.reactiveState.highestPocketNumber;
        }

        this.reactiveState.selectedPocket = newNumber;
    }

    public setHighestPocketNumber(number: number) {
        this.reactiveState.highestPocketNumber = number;
    }

    public setDisplayMode(displayMode: DisplayMode) {
        this.reactiveState.displayMode = displayMode;
    }

    public addPockets(pockets: PocketSummary[]) {
        if (this.reactiveState.allPockets == null) {
            this.reactiveState.allPockets = [...pockets].sort((a, b) => a.number - b.number);
        } else {
            const newValue = [...this.reactiveState.allPockets, ...pockets].sort((a, b) => a.number - b.number);
            this.reactiveState.allPockets = newValue;
        }
    }

    public clearAllPockets() {
        this.reactiveState.allPockets = undefined;
    }

    // public addSpines(newSpines: object) {
    //     const newObject = reactive(Object.assign(this.reactiveState.allSpines, newSpines));
    //     this.reactiveState.allSpines = newObject;
    // }

    // public clearSpines() {
    //     this.reactiveState.allSpines = {};
    // }

    // public addCovers(newCovers: object) {
    //     const newObject = reactive(Object.assign(this.reactiveState.allCovers, newCovers));
    //     this.reactiveState.allCovers = newObject;
    // }

    // public clearCovers() {
    //     this.reactiveState.allCovers = {};
    // }

    // public addCoversLarge(newCoversLarge: object) {
    //     const newObject = reactive(Object.assign(this.reactiveState.allCoversLarge, newCoversLarge));
    //     this.reactiveState.allCoversLarge = newObject;
    // }

    // public clearCoversLarge() {
    //     this.reactiveState.allCoversLarge = {};
    // }
    
    // public addSpinesLarge(newSpinesLarge: object) {
    //     const newObject = reactive(Object.assign(this.reactiveState.allSpinesLarge, newSpinesLarge));
    //     this.reactiveState.allSpinesLarge = newObject;
    // }

    // public clearSpinesLarge() {
    //     this.reactiveState.allSpinesLarge = {};
    // }

    public addPocketDetails(pocket: Pocket) {
        const newObject = reactive(Object.assign(this.reactiveState.allPocketDetails, {[pocket.number]: pocket}));
        this.reactiveState.allPocketDetails = newObject;
    }

    public clearPocketDetails() {
        this.reactiveState.allPocketDetails = {};
    }
}

const stateStore: Store = new Store();
export default stateStore;