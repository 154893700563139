<template>
<div class="loading">
    <fa icon="spinner" spin></fa>
</div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.loading {
    font-size: 2rem;
    min-height: 2rem;
    min-width: 2rem;

    svg {
        width: 2rem;
        height: 2rem;
    }
}
</style>